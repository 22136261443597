import { Global } from '@emotion/react'
import React from 'react'
import tw, { GlobalStyles, styled, css } from 'twin.macro'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/jetbrains-mono/400.css'
import '@fontsource/jetbrains-mono/600.css'

const MyGlobalStyles = () => {
  return (
    <>
      <GlobalStyles />
      <Global
        styles={css`
          .__react_component_tooltip {
            position: fixed;
            opacity: 0 !important;

            &.show {
              opacity: 1 !important;
            }
          }
        `}
      />
    </>
  )
}

const PageContainer = styled.div`
  ${tw`w-full bg-gray-100`}
`

const Layout = ({ children }) => {
  // "scroll-behavior: smooth;" does not work in safari and mobile safari
  // https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/
  // https://github.com/innocenzi/tailwindcss-scroll-snap
  // Use JS instead
  // https://medium.com/@chrisfitkin/how-to-smooth-scroll-links-in-gatsby-3dc445299558
  if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
      easing: 'easeInOutQuad',
    })
  }

  return (
    <PageContainer>
      <MyGlobalStyles />
      {children}
    </PageContainer>
  )
}

export default Layout
