// This is adapted directly from the official gatsby-starter-default
// https://github.com/gatsbyjs/gatsby-starter-default
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import favicon from '../../static/favicon.png'

const SEO = ({
  title,
  description,
  lang,
  meta,
  image,
  fbTwitterImg,
  linkedInImg,
  linkedInImgSml,
}) => {
  const {
    site,
    defaultfbTwitterImage,
    defaultLinkedIn,
    defaultLinkedInSml,
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            author
            titleTemplate
            defaultDescription: description
            siteUrl: url
            twitterUsername: twitter
          }
        }
        defaultfbTwitterImage: file(relativePath: { eq: "site-image.png" }) {
          childImageSharp {
            fixed(width: 1200, height: 630, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
        defaultLinkedIn: file(relativePath: { eq: "site-image.png" }) {
          childImageSharp {
            fixed(width: 1200, height: 627, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
        defaultLinkedInSml: file(relativePath: { eq: "site-image.png" }) {
          childImageSharp {
            fixed(width: 180, height: 110, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
      }
    `,
  )

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
    author,
  } = site.siteMetadata

  const defaultImage = defaultfbTwitterImage.childImageSharp.fixed
  const defaultImageLinkedIn = defaultLinkedIn.childImageSharp.fixed
  const defaultImageLinkedInSml = defaultLinkedInSml.childImageSharp.fixed

  const seo = {
    title: `${title}` || defaultTitle,
    description: description || defaultDescription,
    titleTemplate: titleTemplate || `%s | ${site.siteMetadata.title}`,
    url: `${siteUrl}`,
    image: image
      ? `${siteUrl}${fbTwitterImg.src}`
      : `${siteUrl}${defaultImage.src}`,
    type: `website`,
    fbTwitterImg: fbTwitterImg
      ? `${siteUrl}${fbTwitterImg.src}`
      : `${siteUrl}${defaultImage.src}`,
    linkedInImg: linkedInImg
      ? `${siteUrl}${linkedInImg.src}`
      : `${siteUrl}${defaultImageLinkedIn.src}`,
    linkedInImgSml: linkedInImgSml
      ? `${siteUrl}${linkedInImgSml.src}`
      : `${siteUrl}${defaultImageLinkedInSml.src}`,
    twitterCard: image ? `summary_large_image` : `summary`,
    twitterSite: twitterUsername,
    twitterCreator: twitterUsername,
    siteName: author,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
        // { rel: 'stylesheet', href: 'https://use.typekit.net/' },
      ]}
      // titleTemplate={seo.titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `author`,
          content: seo.siteName,
        },
        {
          property: `article:author`,
          content: seo.siteName,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:type`,
          content: seo.type,
        },
        {
          name: `twitter:card`,
          content: seo.twitterCard,
        },
        {
          name: `twitter:creator`,
          content: seo.twitterCreator,
        },
        {
          name: `twitter:site`,
          content: seo.twitterSite,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.fbTwitterImg,
        },
        {
          name: `twitter:image:alt`,
          content: seo.title,
        },
        // Main Image tag
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:image:secure_url`,
          content: seo.image,
        },
        {
          property: `og:image:width`,
          content: defaultImage.width,
        },
        {
          property: `og:image:height`,
          content: defaultImage.height,
        },
        // LinkedIn
        {
          property: `og:image`,
          content: seo.linkedInImg,
        },
        {
          property: `og:image:secure_url`,
          content: seo.linkedInImg,
        },
        {
          property: `og:image:width`,
          content: defaultImageLinkedIn.width,
        },
        {
          property: `og:image:height`,
          content: defaultImageLinkedIn.height,
        },
        // LinkedIn Small
        {
          property: `og:image`,
          content: seo.linkedInImgSml,
        },
        {
          property: `og:image:secure_url`,
          content: seo.linkedInImgSml,
        },
        {
          property: `og:image:width`,
          content: defaultImageLinkedInSml.width,
        },
        {
          property: `og:image:height`,
          content: defaultImageLinkedInSml.height,
        },
        {
          name: `image`,
          content: seo.image,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: null,
  image: null,
  article: false,
  fbTwitterImg: null,
  linkedInImg: null,
  linkedInImgSml: null,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.bool,
  article: PropTypes.bool,
  fbTwitterImg: PropTypes.string,
  linkedInImg: PropTypes.string,
  linkedInImgSml: PropTypes.string,
}

export default SEO
